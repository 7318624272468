import {
  MutationFunction,
  QueryCache,
  QueryClient,
  QueryFilters,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from "@tanstack/react-query";
import { MutateOptions } from "@tanstack/query-core/src/types";
import { useEffect, useRef } from "react";

export type WSQueryKey =
  | QueryKey
  /**
   * @deprecated This property is deprecated. An array is expected instead.
   */
  | string;

const queryCache = new QueryCache();

const fixKey = (key: WSQueryKey): QueryKey => {
  const fixedKey = Array.isArray(key) ? key : [key];
  return fixedKey as any as QueryKey;
};

// Create a client instance
export const WSQueryClient = new QueryClient({
  queryCache: queryCache,
  defaultOptions: {
    mutations: {},
    queries: {
      retry: (failureCount, error: any) => {
        const isNetworkError = error?.message === "Network Error";
        const isServerError =
          typeof error?.response?.status === "number" &&
          error.response.status >= 500;

        if (isNetworkError || isServerError) {
          return true;
        }

        return false;
      },
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: query => {
        return query.state.isInvalidated;
      },
      retryOnMount: true,
      retryDelay: (attemptIndex: number) =>
        Math.min(1000 * 2 ** attemptIndex, 5000)
    }
  }
});

export const WSQueryCache = {
  getQueryData<Result = unknown>(key: WSQueryKey, filters?: QueryFilters) {
    return WSQueryClient.getQueryData<Result>(fixKey(key), filters);
  },
  setQueryData<Data = unknown>(key: WSQueryKey, data: Data) {
    return WSQueryClient.setQueryData(fixKey(key), data);
  },
  removeQueries(key: WSQueryKey, filters?: QueryFilters) {
    try {
      WSQueryClient.removeQueries(fixKey(key), {
        exact: false,
        ...filters
      });
    } catch (error) {
      console.warn("WSQueryCache - removeQueries:", error);
    }

    return null;
  },
  async refetchQueries(key: WSQueryKey, filters?: QueryFilters) {
    try {
      await WSQueryClient.refetchQueries(fixKey(key), {
        exact: false,
        type: "all",
        refetchPage: () => true,
        ...filters
      });
    } catch (error) {
      console.warn("WSQueryCache - refetchQueries:", error);
    }

    return null;
  },
  async invalidateQueries(key: WSQueryKey, filters?: QueryFilters) {
    try {
      await WSQueryClient.invalidateQueries(fixKey(key), {
        exact: false,
        type: "all",
        refetchType: "active",
        refetchPage: () => true,
        ...filters
      });
    } catch (error) {
      console.warn("WSQueryCache - invalidateQueries:", error);
    }

    return null;
  },
  clear() {
    return WSQueryClient.clear();
  }
};

export type WSQueryResult<TResult, TError = any> = UseQueryResult<
  TResult,
  TError
> & {
  isDisabled: boolean;
  refetchData: () => Promise<TResult | undefined>;
};

export interface WSQueryConfig<TQueryFnData, TError = any, TData = TQueryFnData>
  extends Omit<
    UseQueryOptions<TQueryFnData, TError, TData>,
    "queryFn" | "queryKey"
  > {}

export function useWSQuery<TQueryFnData, TError = any, TData = TQueryFnData>(
  queryKey: WSQueryKey,
  queryFn: (variables?: any) => Promise<TQueryFnData>,
  queryConfig?: WSQueryConfig<TQueryFnData, TError>
): WSQueryResult<TData, TError> {
  const { onSuccess, onError, ...config } = queryConfig || {};
  const refIsSuccessTriggered = useRef(false);
  const refIsErrorTriggered = useRef(false);

  const fixedConfig = {
    // Enabled should be boolean!
    ...(queryConfig?.enabled !== undefined
      ? { enabled: !!queryConfig?.enabled }
      : {})
  };

  const query = useQuery<TQueryFnData, TError, TData, any>({
    queryKey: fixKey(queryKey),
    queryFn,
    ...config,
    ...fixedConfig
  });

  // onSuccess and onError is deprecated and behavior is not guaranteed, se we created a custom hook to handle this for legacy code
  useEffect(() => {
    if (
      query.isSuccess &&
      query.dataUpdatedAt &&
      !refIsSuccessTriggered.current
    ) {
      refIsSuccessTriggered.current = true;
      refIsErrorTriggered.current = false;
      onSuccess?.(query.data as any as TQueryFnData);
    }
  }, [onSuccess, query.dataUpdatedAt, query.isSuccess]);

  useEffect(() => {
    if (query.isError && query.errorUpdatedAt && !refIsErrorTriggered.current) {
      refIsErrorTriggered.current = true;
      refIsSuccessTriggered.current = false;
      onError?.(query.error as TError);
    }
  }, [onError, query.errorUpdatedAt, query.isError]);

  return {
    ...query,
    refetchData: async () => {
      const resultQuery = await query.refetch();
      return resultQuery.data;
    },
    isDisabled: query.status === "loading" && query.fetchStatus === "idle"
  };
}

export interface WSInfiniteQueryConfig<
  TQueryFnData,
  TError = any,
  TData = TQueryFnData,
  TQueryData = TQueryFnData
> extends Omit<
    UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData>,
    "queryFn" | "queryKey"
  > {}

export type WSInfiniteQueryResult<TData, TError = any> = UseInfiniteQueryResult<
  TData,
  TError
>;

export function useWSInfiniteQuery<
  TQueryFnData,
  TError = any,
  TData = TQueryFnData
>(
  queryKey: WSQueryKey,
  queryFn: UseInfiniteQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryFnData
  >["queryFn"],
  queryConfig?: WSInfiniteQueryConfig<TQueryFnData, TError, TData, TQueryFnData>
): WSInfiniteQueryResult<TData, TError> {
  return useInfiniteQuery({
    queryKey: fixKey(queryKey),
    queryFn,
    ...(queryConfig as any)
  });
}

type WSMutateOptions<
  TData,
  TError = any,
  TVariables = void,
  TContext = unknown
> = MutateOptions<TData, TError, TVariables, TContext> & {
  /**
   * @deprecated This property is invalid in current version.
   * Use the built-in TanStack Query error handling instead.
   */
  throwOnError?: boolean;
};

export type WSMutateFunction<
  TData,
  TError = any,
  TVariables = void,
  TContext = unknown
> = (
  variables?: TVariables,
  options?: WSMutateOptions<TData, TError, TVariables, TContext>
) => Promise<TData>;

export type WSMutationResult<
  TData,
  TError = any,
  TVariables = any,
  TContext = unknown
> = Readonly<
  [
    WSMutateFunction<TData, TError, TVariables, TContext>,
    UseMutationResult<TData, TError, TVariables, TContext>
  ]
>;

export interface WSMutationConfig<
  TResult,
  TError = any,
  TVariables = any,
  TContext = unknown
> extends Omit<
    UseMutationOptions<TResult, TError, TVariables, TContext>,
    "mutationFn" | "mutationKey"
  > {
  /**
   * @deprecated This property is deprecated.
   * Use the built-in TanStack Query error handling instead.
   */
  throwOnError?: boolean;
  dependencies?:
    | ((result: TResult, variables: TVariables) => WSQueryKey[])
    | WSQueryKey[];
  awaitDependencies?:
    | ((result: TResult, variables: TVariables) => WSQueryKey[])
    | WSQueryKey[];
}

export type { WSMutationConfig as WSMutationsConfig };

export function useWSMutation<
  TData,
  TError = any,
  TVariables = any,
  TContext = unknown
>(
  mutationFunc: MutationFunction<TData, TVariables>,
  config?: WSMutationConfig<TData, TError, TVariables, TContext>
): Readonly<WSMutationResult<TData, TError, TVariables, TContext>> {
  const { awaitDependencies, dependencies, ...restConfig } = config || {};

  const mappedConfig: WSMutationConfig<TData, TError, TVariables, TContext> =
    restConfig;

  const mutation = useMutation<TData, TError, TVariables, TContext>({
    mutationFn: async (variables: TVariables) => {
      let response = await mutationFunc(variables);

      if (Array.isArray(awaitDependencies)) {
        for (const dependency of awaitDependencies) {
          await WSQueryCache.refetchQueries(dependency);
        }
      } else if (typeof awaitDependencies === "function") {
        const resultDynamicDependencies = awaitDependencies(
          response,
          variables
        );
        for (const dependency of resultDynamicDependencies) {
          await WSQueryCache.refetchQueries(dependency);
        }
      }

      if (Array.isArray(dependencies)) {
        for (const dependency of dependencies) {
          await WSQueryCache.invalidateQueries(dependency);
        }
      } else if (typeof dependencies === "function") {
        const resultDynamicDependencies = dependencies(response, variables);
        for (const dependency of resultDynamicDependencies) {
          await WSQueryCache.invalidateQueries(dependency);
        }
      }

      return response;
    },
    ...mappedConfig
  });

  const mutationAsyncFn = (
    variables: TVariables,
    options?: WSMutateOptions<TData, TError, TVariables, TContext>
  ) => {
    const {
      throwOnError: localThrowOnError,
      onSuccess,
      onError,
      ...restOptions
    } = options || {};

    const throwOnError = localThrowOnError ?? config?.throwOnError ?? false;

    return mutation
      .mutateAsync(variables, restOptions)
      .then(async data => {
        try {
          await onSuccess?.(data, variables, undefined as any);
        } catch (error) {
          console.error("WSMutation - onSuccess:", error);
        }

        return data;
      })
      .catch(async error => {
        try {
          await onError?.(error, variables, undefined);
        } catch (error) {
          console.error("WSMutation - onError:", error);
        }

        if (throwOnError !== false) {
          return Promise.reject(error);
        }
      });
  };

  return [
    mutationAsyncFn as WSMutateFunction<TData, TError, TVariables, TContext>,
    mutation
  ] as const;
}
